import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import { QandA } from '../../components/qanda'

// markup
const MetOperaPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Metropolitan Opera | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operahouses/metopera" />
          <meta name="description" content="The Metropolitan Opera House at the Lincoln Centre, NYC. How to get tickets, what to wear and where to find the stage door!" />
        </Helmet>
        <Hero title="The Metropolitan Opera"/>
        <MainBodySection>
                    
          <Header title="The Metropolitan Opera"/>

          <p>The Metropolitan Opera (known usually as just the "Met") in New York City is the 800 pound gorilla of the operatic world. A huge auditorium, 3800 seats, and a vast stage mean that shows here tend to be enormous in scale. Whether this makes for great opera is up for debate, but since its foundation the Met has been a leading house for great singers and productions.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <ImageFull>
            <StaticImage src="../../images/operahouses/metopera/Turandot_MartySohl.jpg" alt="Turandot - Act III Met"/>
            <figcaption>Act III of Zeffirelli's Met production of <i>Turandot</i> &copy; Marty Sohl/Metropolitan Opera</figcaption>
          </ImageFull>

          <p>The company presents around 25-30 different operas a year with a strong emphasis on 19th Century, Romantic operas. The size of the place renders Baroque works ill suited and the conservative tastes of the audience makes anything post-Puccini a pretty hard sell. Since Peter Gelb came to lead the company in 2006, attempts have been made at dragging the Met away from its highly conservative past but massive, rather silly, "traditional" productions still loom large over the repertory.</p>

          <p>The most notable recent developments have surrounded the Met's wildly successful <a href="https://www.metopera.org/season/in-cinemas/">live in HD</a> screenings. These are performances streamed live to cinemas around the world, with terrific backstage interviews accompanying the main event. They always use the Saturday matinees for this, ensuring that Europe receives the broadcasts at an agreeable evening hour. The tickets aren't the cheapest, around $25, but compared with top price tickets in the house: these are a steal!</p>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="FAQ: Attending the MET"/>

          <QandA>
              <li>
                <h4>Is there a Dress code?</h4>
                <p>No there most definitely isn’t. You’ll see a range of clothing on show from flamboyant black tie to decidedly tatty sweaters. If you feel like dressing up then go for it, but I’ve pitched up many times in dirty jeans and a t-shirt without raising any eyebrows. The only exception to this is are the 4 annual galas which have encouraged evening wear (tuxedos and ball gowns de rigeur). These performances are also considerably more expensive.</p>
              </li>

              <li>
                <h4>Programmes and Information?</h4>
                <p>Playbills are handed out for free and contain a cast list and a few paragraphs about the opera (plus lots of advertising!). There's nothing approaching the substance of European style programmes but they're free so one can't really complain!</p>
              </li>

              <li>
                <h4>Cloakroom?</h4>
                <p>There is a cloakroom though for anyone entering from the Lincoln Center plaza it's not the easiest thing to find. It's one floor down from the plaza entrance (by the very glamorous car park entrance), on the lefthand side. None of us here has ever used it so we aren't sure how bad the lines can get but our impression is it's never much of a problem.</p>
              </li>

              <li>
                <h4>Can I get in late?</h4>
                <p>I’m afraid this is a big no-no. The Met are slightly more flexible than some and might let you in during the overture (prepare to be frowned at... by us if no one else!), but in general you'll have to wait till an interval or Act break.</p>
              </li>

              <li>
                <h4>Where's the stage door?</h4>
                <p>The Met's stage door may be the least glamorous we've ever come across being a strange side door that opens straight into the car park warren beneath the Lincoln Center. If you don't know where it is then asking for directions is probably the easiest. If you're up for more of an adventure: go down the escalator by the Met shop, continue through two sets of white double doors and then turn left and it's straight on. You'll know you've found it by the small stretch of red carpet and the crowd of punters waiting to get their paraphernalia signed.</p>
              </li>

              <li>
                <h4>Where Can I Buy Tickets?</h4>
                <p>Tickets can be bought by a variety of means but the standard three are: in person at the box office, over the phone (212-362-6000) or on the Met’s website (<a href="http://www.metopera.org" title="The Website of the Metropolitan Opera">http://www.metopera.org</a>). As with far too many NYC venues the Met swindles their clients with ridiculous extra fees if you purchase tickets over the phone or online: buying in person will save you $7 dollars per ticket...</p>
              </li>


              <li>
                <h4>Sold Out Shows?</h4>

                <p>Depressing as it sounds: very, very little sells out at the Met these days. Other than warhorses with top five singer casting or highly publicised new productions tickets are almost always available up to the curtain.</p>

                <p>On the occasions when shows do sell out you have a few options. Monday through Thursday the Met has extremely good value rush tickets (we go into these in the cheap ticket section), 150 available 2 hours before the show. Standing is also available on the day, though at 20+ dollars these aren't the best value.</p>

                <p>Whatever you do, don't use the resellers on the Lincoln Center Plaza. They use dubious methods to acquire tickets, like paying people to stand in line for the rush tickets, and shouldn't be encouraged. You may have luck around the entrance to the Met, where occasional regular ticket holders sometimes try to offload spare tickets they can't use.</p>
              </li>
          </QandA>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="FAQ: Cheap Tickets!"/>

          <QandA>

            <li>
              <h4>Are all the seats expensive?</h4>
              <p>We'd be lying if we suggested you could buy a front row stalls seat for less than about $250, but the top price isn't really reflective of the range available. The Met is a huge theatre and the family circle (the top tier) has tickets from about $20 to $40. Though you are far away from the action, the view is actually extremely good, the sound perfect and frankly $20 is a lot less than you'll pay for a far worse seat in a Broadway houses.</p>

              <p>There's also standing but at $15-30 dollars you're always better buying a seat unless you're a keen stander (they do exist) or the show is sold out!</p>
            </li>

            <li>
              <h4>Rush Tickets</h4>
              <p>One of the greatest Met policies of the last few decade has been the Rush tickets. These were originally endowed by the late Agnes Varis, and comprise orchestra seats at every performance available for $25 (a ridiculously good deal). These are sold online on the day of the performance, (<a href="http://www.metopera.org/Season/Tickets/Rush-Page/">you can find the details here</a>). You'll need to be fast, they sell out essentially the moment they're made available, but if you're at the ready they're definitely gettable.</p>
            </li>

            <li>
              <h4>Discounts?</h4>
              <p>Beyond the Rush tickets the Met doesn't do a great deal of discounting, even when there are tickets to sell. For students there are plenty of options (see below) but otherwise Rush and Standing are the two best ways of getting good value deals.</p>
            </li>

            <li>
              <h4>Student Deals?</h4>
              <p>The Met's student scheme is absolutely fabulous. Unlike some other houses they do not set aside tickets specifically for students (which is less impressive) but they are extremely good at offering spare tickets to students at massive discounts. They have a page on their website (<a href="http://www.metopera.org/Season/Tickets/Student-Tickets/">here</a>) that allows students to buy tickets ahead of time for $37.50 when the house is undersold. These are rarely the best seats in the house but are often rear orchestra and good seats in the first few tiers, a steal for the price.</p>

              <p>Where the Met gets truly brilliant for students is that on many nights the box office will sell you best available tickets a few hours before the show starts at the same $37.50 price and these can be unbelievable seats (we've sat in the middle/front of the orchestra many times this way). When and whether they put these on sale is voodoo to us (sometimes undersold performances aren't available for no obvious reason whilst other nights nearly sold out shows offer tickets), but if you've got a spare night this is always worth a try (at least while the Met continues to rarely sell out).</p>
            </li>

          </QandA>

        </MainBodySection>
    </Layout>
  )
}

export default MetOperaPage
